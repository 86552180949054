// Customize any SASS variables here
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "~bootstrap/scss/bootstrap.scss";

html, body {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page-container {
  margin: 1rem;
}

a{
  text-decoration: none;
  color: beige;
}

a:hover{
  color:aliceblue
}

@import "styles/header";
@import "styles/layout";
@import "styles/panels";
