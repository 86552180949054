.panels > div {
  // Add a black border around each panel for debugging
  border: 1px solid black;
}

.panels {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 225px auto auto;
  grid-template-rows: auto auto;
}
.panel-info {
  grid-row: span 2;
}
.panel-positions {
  grid-column: span 2;
}

.panel-sales {
  grid-column: span 2;
}

@media (max-width: 750px) {
  .panels {
    grid-template-columns: 225px auto;
    grid-template-rows: auto auto auto;
  }
  .panel-positions {
    grid-column: span 2;
  }
  .panel-sales {
    grid-column: span 2;
  }
}

@media (max-width: 500px) {
  .panels {
    grid-template-columns: auto;
  }
  .panel-positions {
    grid-column: span 1;
  }
  .panel-sales {
    grid-column: span 1;
  }  
}
